@mixin flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

@mixin Headline {

    h3 {
        color: $brown;
        font-size: 2.3vw;
        font-family: $font-3;
        margin-bottom: 2%;
        text-transform: capitalize;
    }

    p {
        font-size: 1.2vw;
        line-height: 180%;
        font-family: $font-2;
        color: rgba($color: #000, $alpha: 0.7);
    }

    @media (min-width: 320px) and (max-width: 767px) {
        h3 {
            font-size: 5vw;
        }

        p,
        li {
            font-size: 3.5vw;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        h3 {
            font-size: 3vw;
        }

        p {
            font-size: 2vw;
        }
    }
}