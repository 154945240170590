@import url('https://fonts.googleapis.com/css2?family=Lato&family=Playfair+Display&family=Poppins&display=swap');

// colors

$black: #343434;
$light-brown: #b48058;
$brown: #ac5c3c;

// gradient
$gradient: linear-gradient(90deg, rgba(172, 92, 60, 1) 0%, rgba(180, 128, 88, 1) 100%);
;
$gradient-2: linear-gradient(90deg, rgba(74, 89, 148, 1) 0%, rgba(108, 116, 171, 1) 100%);

// shadows
$box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
$text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);

// padding-margin
$padding: 0 5% 5% 5%;

// transition
$transition: .5s all ease;

// font-family
$header-font: 'Roboto', sans-serif;
$font-2: 'Lato', sans-serif;
$font-3: 'Playfair Display', serif;
$font-4: 'Poppins', sans-serif;